<template>
  <CSidebar
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        id="logo-main"
        size="custom-size"
        :height="56"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="sidebarItems" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from "./SidebarItems";
import { mapState } from "vuex";
export default {
  name: "TheSidebar",
  extends: SidebarItems,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    ...mapState({
      minimize: (state) => state.sidebarMinimize,
    }),
  },
  mounted: async function () {
    //await this.getPermissionList();
  },
  methods: {
    getPermissionList: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/admin/permission`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.sidebarItems[0]._children = await this.sidebarItems[0]._children.filter(
          (x) => {
            if (data.detail.indexOf(x.id) != -1) {
              if (x._children) {
                x._children = x._children.filter((y) => {
                  if (data.detail.indexOf(y.id) != -1) {
                    if (y.items) {
                      y.items = y.items.filter(
                        (z) => data.detail.indexOf(z.id) != -1
                      );
                    }
                    return y;
                  }
                });
              }
              return x;
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
#logo-main {
  background-image: url("/img/brand/logo_skl.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>