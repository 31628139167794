<script>
export default {
  name: 'nav',
  data() {
    return {
      sidebar: {
        _name: 'CSidebarNav',
        _children: [
          {
            id: 50,
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-chart-line'
          },
          {
            id: 1,
            _name: 'CSidebarNavItem',
            name: 'User',
            to: '/user',
            icon: 'cil-user'
          },
          {
            id: 6,
            _name: 'CSidebarNavItem',
            name: 'Admin',
            to: '/admin',
            icon: 'cil-star'
          },
          {
            id: 7,
            _name: 'CSidebarNavDropdown',
            name: 'Report',
            route: '/report',
            icon: 'cil-list',
            _children: [
              {
                id: 41,
                _name: 'CSidebarNavDropdown',
                name: 'General',
                icon: 'cil-list',
                items: [
                  {
                    id: 17,
                    _name: 'CSidebarNavItem',
                    name: 'Register Line Connect',
                    to: '/report/register',
                    icon: 'cil-file'
                  },
                  {
                    id: 18,
                    _name: 'CSidebarNavItem',
                    name: 'Installment Information',
                    to: '/report/installment',
                    icon: 'cil-file'
                  },
                  {
                    id: 8,
                    _name: 'CSidebarNavItem',
                    name: 'Barcode/QRCode',
                    to: '/report/barcode',
                    icon: 'cil-file'
                  },
                  {
                    id: 9,
                    _name: 'CSidebarNavItem',
                    name: 'Online Payment',
                    to: '/report/onlinePayment',
                    icon: 'cil-file'
                  }
                ]
              },
              {
                id: 19,
                _name: 'CSidebarNavDropdown',
                name: 'Notification',
                icon: 'cil-list',
                items: [
                  {
                    id: 20,
                    name: 'Notification Installment',
                    to: '/report/notiInstallment',
                    icon: 'cil-file'
                  },
                  {
                    id: 21,
                    name: 'Notification Overdue',
                    to: '/report/notiOverdue',
                    icon: 'cil-file'
                  },
                  {
                    id: 22,
                    name: 'Notification Overdue 1',
                    to: '/report/notiOverdue1',
                    icon: 'cil-file'
                  },
                  {
                    id: 23,
                    name: 'Notification Receipt',
                    to: '/report/notiReceipt',
                    icon: 'cil-file'
                  },
                  {
                    id: 24,
                    name: 'Notification Receipt Cancel',
                    to: '/report/notiReceiptCancel',
                    icon: 'cil-file'
                  },
                  {
                    id: 25,
                    name: 'Notification Return Cash',
                    to: '/report/notiReturnCash',
                    icon: 'cil-file'
                  },
                  {
                    id: 26,
                    name: 'Notification RI',
                    to: '/report/notiRi',
                    icon: 'cil-file'
                  },
                  {
                    id: 27,
                    name: 'Notification Tax',
                    to: '/report/notiTax',
                    icon: 'cil-file'
                  },
                  {
                    id: 28,
                    name: 'Notification Point Expire',
                    to: '/report/notiPoint',
                    icon: 'cil-file'
                  },
                  {
                    id: 29,
                    name: 'Notification Redeem SKL Point',
                    to: '/report/notiRedeemPoint',
                    icon: 'cil-file'
                  },
                  {
                    id: 29,
                    name: 'Notification Earn Point',
                    to: '/report/notiEarnPoint',
                    icon: 'cil-file'
                  },
                  {
                    id: 53,
                    name: 'Notification Invoice',
                    to: '/report/notiInvoice',
                    icon: 'cil-file'
                  }
                ]
              },
              {
                id: 12,
                _name: 'CSidebarNavDropdown',
                name: 'Contract',
                icon: 'cil-list',
                items: [
                  {
                    id: 13,
                    _name: 'CSidebarNavItem',
                    name: 'Unregister',
                    to: '/report/unregister',
                    icon: 'cil-file'
                  },
                  {
                    id: 14,
                    name: 'OTP',
                    to: '/report/otp',
                    icon: 'cil-file'
                  }
                ]
              },
              {
                id: 42,
                _name: 'CSidebarNavDropdown',
                name: 'Other',
                icon: 'cil-list',
                items: [
                  {
                    id: 10,
                    _name: 'CSidebarNavItem',
                    name: 'Map',
                    to: '/report/map',
                    icon: 'cil-file'
                  },
                  {
                    id: 11,
                    _name: 'CSidebarNavItem',
                    name: 'Check App',
                    to: '/report/checkapp',
                    icon: 'cil-file'
                  },
                  {
                    id: 35,
                    _name: 'CSidebarNavItem',
                    name: 'Calculator',
                    to: '/report/calculator',
                    icon: 'cil-file'
                  },
                  {
                    id: 36,
                    _name: 'CSidebarNavItem',
                    name: 'Point',
                    to: '/report/point',
                    icon: 'cil-file'
                  },
                  {
                    id: 37,
                    _name: 'CSidebarNavItem',
                    name: 'Report Promotion',
                    to: '/report/promotion',
                    icon: 'cil-file'
                  },
                  {
                    id: 39,
                    _name: 'CSidebarNavItem',
                    name: 'Loans Online Register',
                    to: '/report/LoanOnlineRegister',
                    icon: 'cil-file'
                  },
                  {
                    id: 15,
                    name: 'Contract Infomation',
                    to: '/report/contractInfomation',
                    icon: 'cil-file'
                  },
                  {
                    id: 16,
                    name: 'Request Contract',
                    to: '/report/requestContract',
                    icon: 'cil-file'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            _name: 'CSidebarNavDropdown',
            name: 'Suggest',
            route: '/Feedback',
            icon: 'cil-list',
            _children: [
              {
                id: 31,
                _name: 'CSidebarNavItem',
                name: 'Suggest List',
                to: '/feedback',
                icon: 'cil-comment-bubble'
              },
              {
                id: 32,
                _name: 'CSidebarNavItem',
                name: 'Edit Question/Answer',
                to: '/feedback/editfeedbackquestion',
                icon: 'cil-comment-bubble'
              }
            ]
          },
          {
            id: 3,
            _name: 'CSidebarNavItem',
            name: 'Document Track',
            to: '/document',
            icon: 'cil-file'
          },
          {
            id: 46,
            _name: 'CSidebarNavDropdown',
            name: 'Note Code',
            route: '/notecode',
            icon: 'cil-list',
            _children: [
              {
                id: 47,
                _name: 'CSidebarNavItem',
                name: 'Report Note Code',
                to: '/notecodereport',
                icon: 'cil-file'
              },
              {
                id: 48,
                _name: 'CSidebarNavItem',
                name: 'Notification Note Code',
                to: '/notificationnotecode',
                icon: 'cil-file'
              }
            ]
          },
          {
            id: 49,
            _name: 'CSidebarNavItem',
            name: 'Audit Log',
            to: '/audit',
            icon: 'cil-list'
          },
          {
            id: 43,
            _name: 'CSidebarNavDropdown',
            name: 'Setting',
            icon: 'cil-list',
            _children: [
              {
                id: 44,
                _name: 'CSidebarNavDropdown',
                name: 'Payment',
                icon: 'cil-list',
                items: [
                  {
                    id: 4,
                    _name: 'CSidebarNavItem',
                    name: 'Payment Channel',
                    to: '/paymentchannel',
                    icon: 'cib-cc-mastercard'
                  },
                  {
                    id: 29,
                    _name: 'CSidebarNavItem',
                    name: 'Bank',
                    to: '/bank',
                    icon: 'cil-bank'
                  }
                ]
              },
              {
                id: 33,
                _name: 'CSidebarNavItem',
                name: 'Notification Settings',
                to: '/notification/settings',
                icon: 'cil-list'
              },
              {
                id: 5,
                _name: 'CSidebarNavItem',
                name: 'Line Message Management',
                to: '/linemessage',
                icon: 'cil-chat-bubble'
              },
              {
                id: 45,
                _name: 'CSidebarNavItem',
                name: 'Map - Product List',
                to: '/map/productlist',
                icon: 'cil-chat-bubble'
              },
              {
                id: 34,
                _name: 'CSidebarNavItem',
                name: 'Promotion',
                to: '/promotion',
                icon: 'cil-bullhorn'
              },
              {
                id: 38,
                _name: 'CSidebarNavItem',
                name: 'Redirection',
                to: '/urlredirection',
                icon: 'cil-link'
              },
              {
                id: 54,
                _name: 'CSidebarNavItem',
                name: 'Line Rich Menu',
                to: '/rich-menu',
                icon: 'cil-link'
              }

            ]
          },
          {
            id: 40,
            _name: 'CSidebarNavItem',
            name: 'Terms & Conditions',
            to: '/termsandconditions',
            icon: 'cil-file'
          }
        ]
      }
    };
  },
  computed: {
    sidebarItems() {
      return [this.sidebar];
    }
  },
  created() {
    this.getPermissionList();
  }
};
</script>
