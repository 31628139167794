<template>
  <CHeader with-subheader>
    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="/img/brand/logo_skl.svg"
      width="97"
      height="46"
      alt="CoreUI Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-1 mr-sm-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <ul>
            <li v-for="lang in languages" :key="lang" @click="changeLang(lang)">{{lang}}</li>
          </ul>
        </CHeaderNavLink>
      </CHeaderNavItem>-->
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>-->
      <!-- <TheHeaderDropdownAccnt /> -->
      <CHeaderNavItem class="d-md-down-none mx-2 font-weight-bold">{{displayName}}</CHeaderNavItem>
      <CHeaderNavItem class="mx-2">
        <a href="#" @click.prevent="logout">
          <CIcon name="cil-lock-locked" />
          <span class="ml-1">Logout</span>
        </a>
      </CHeaderNavItem>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  data() {
    return {
      displayName: "",
      // languages: ["en", "th"]
    };
  },
  created: async function () {
    await this.getProfile();
  },
  methods: {
    //   changeLang(lang) {
    //     this.$i18n.locale = lang;
    //   },
    getProfile: async function () {
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/info`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.displayName =
          resData.detail.admin.firstName + " " + resData.detail.admin.lastName;
      }
    },
    logout: async function () {
      let pathLogout = '/logout';
      let methodApi = "post";
      if (confirm("Are you sure you want to logout?")) {
        if(this.$cookies.get("back_office_admin_is_login_azure") === 'true'){
          pathLogout = '/UnPairAzure';
          methodApi = "get";
        }

        let resData = await this.$callApi(
          methodApi,
          `${this.$baseUrl}/api${pathLogout}`,
          null,
          this.$headers,
          null
        );
        
        this.$cookies.remove("token");
        this.$cookies.remove("back_office_admin_is_login_azure");
        window.location.href = "/";
      }
    },
  },
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
