<template>
  <CFooter>
    <div>
      <span class="ml-1">SKL &copy; 2020</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
